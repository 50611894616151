import request from "@/utils/request";
export function column(data) {
    return request({
        url: '/archives/portal/queryForPage',
        method: 'post',
        data
    })
}

export function hycase(data) {
    return request({
        url: '/archives/portal/selectColumnNewsInfo',
        method: 'post',
        data
    })
}