<template>
  <div class="case">
    <div class="banner">
      <img src="@/assets/img/case_banner.jpg" alt="">
      <h3>经典案例</h3>
    </div>
    <div class="container">

      <div class="button-group">
        <a-button @click="getList(0, 0)">全部行业</a-button>
        <a-button v-for="(item, index) in hylist" @click="getList(item.id, item.pid)" :key="item.id">{{ item.name }}
        </a-button>
      </div>

      <div class="case-list">
        <div class="case-list-item" v-for="(item, index) in list">
          <router-link :to="{ path: '/Article', query: { id: item.id, title: item.title, content: item.content } }">
            <div class="media">
              <div class="case-top"><img :src="item.picture" alt=""></div>
              <div class="case-bottom">
                <h3>{{ item.title }}</h3>
              </div>
            </div>
          </router-link>

        </div>
      </div>

      <div class="pagination">
        <a-pagination v-model:current="current" :total="total" :pageSize="pageSize" :hideOnSinglePage="true">
          <template #itemRender="{ type, originalElement }">
            <a v-if="type === 'prev'">上一页</a>
            <a v-else-if="type === 'next'">下一页</a>
            <component :is="originalElement" v-else></component>
          </template>
        </a-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { column, hycase } from "@/api/case";

export default {
  name: "Case"
  , data() {
    return {
      list: []
      , total: 0
      , current: 1
      , pageSize: 20
      , hylist: null
      , queryId: ''
      , pid: ''
    }
  }
  , mounted() {
  }
  , watch: {
    '$route': function () {
      // console.log("Case $route", this.$route.query.columnId)
      if (this.$route.query.columnId) {
        this.queryId = this.$route.query.columnId;
        this.pid = this.$route.query.pid;
        if (this.pid == 0) {
          this.getColumn(this.queryId);

        } else {
          this.getColumn(this.pid);
        }

        // 取所有数据
        this.getList(this.queryId, this.pid);

      }
    }
  }
  , created() {
    // this.getList();
    this.queryId = this.$route.query.columnId;
    this.pid = this.$route.query.pid;
    console.log("Case columnId:", this.queryId, this.pid);
    this.getColumn(this.queryId);
    // 取所有数据
    this.getList(this.pid, this.columnId);
  }

  , methods: {
    getColumn(pid) {
      const param = { pid }
      column(param).then(response => {
        console.log("getColumn", response);
        this.hylist = response.data.list;
      })
    },

    getList(id, pid) {
      let queryId = 0;
      if (id == 0) {
        queryId = this.queryId;
      } else {
        queryId = id
      }
      const queryData = {
        id: queryId
      }
      hycase(queryData).then(response => {
        this.list = response.data.list;
        this.total = response.data.total;
        // this.$router.push('/case');
        console.log("queryData this.list", this.list);
      })
    },
  }

}
</script>

<style scoped lang="scss">
.banner {
  position: relative;

  &>img {
    width: 100%;
  }

  &>h3 {
    font-size: 46px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

@media (min-width: 320px) {
  .banner {
    height: 200px;

    &>img {
      height: 200px;
    }
  }
}

@media (min-width: 768px) {
  .banner {
    height: 300px;

    &>img {
      height: 300px;
    }
  }
}

@media (min-width: 992px) {
  .banner {
    height: 400px;

    &>img {
      height: 400px;
    }
  }
}

@media (min-width: 1200px) {
  .banner {
    height: 400px;

    &>img {
      height: 400px;
    }
  }
}

.case {
  background-color: #f4f4f4;

  &>.container {
    padding: 40px 0;
  }
}

.case-list {
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap-reverse;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;

  & .case-list-item {
    @media (min-width: 320px) {
      width: 100%;
    }

    @media (min-width: 768px) {
      width: 33.333333%;
    }

    @media (min-width: 992px) {
      width: 25%;
    }

    @media (min-width: 1200px) {
      width: 25%;
    }

    padding: 10px;

    & .media {
      background: #fff;
      box-shadow: 0 8px 26px 0 rgb(229 229 229 / 50%);
      border-radius: 8px;
      //border: 1px solid #F9F9F9;
      cursor: pointer;
      transition: all .5s;
      transform: translateZ(0);
      overflow: hidden;

      &>.case-top {
        &>img {
          display: block;
          width: 270px;
          height: 175px;
          margin: auto;
        }
      }

      &>.case-bottom {
        padding: 10px 18px;
        text-align: center;

        & h3 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #333333;
          line-height: 27px;
          text-overflow: ellipsis;
          height: 48px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          white-space: break-spaces;
          -webkit-box-orient: vertical;
        }
      }
    }

    &:hover {
      transform: translate3d(0, -2px, 0);
      box-shadow: 0 8px 26px 0 #fafafa;
    }
  }
}

.button-group {
  text-align: center;

  & .ant-btn {
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: none;
    padding: 8px 15px;
    height: auto;
  }
}
</style>